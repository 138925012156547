import React from 'react';
import classNames from 'classnames';
import { Trans } from '@wix/yoshi-flow-editor';

import TimeAgo from '@app/external/common/components/time-ago';
import type { NormalizedPost } from '@app/external/common/types';
import { isPostUpdatedAfterFirstPublish } from './utils';
import styles from './post.scss';

const PostUpdatedDate: React.FC<{ post: NormalizedPost }> = ({ post }) =>
  isPostUpdatedAfterFirstPublish(post) ? (
    <p className={classNames(styles.updateDate, styles.mobileContainer)}>
      <Trans
        i18nKey="post.update-date-new"
        components={[<TimeAgo time={post.lastPublishedDate} />]}
      />
    </p>
  ) : null;

export default PostUpdatedDate;
